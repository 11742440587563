import axios from '../../plugins/axios.js';

const getLinks = (id, res_type, config = undefined) => {
  return axios
    .get(`/api/v1/repossessions/${id}/links/${res_type}`, config)
    .then(({ data }) => data.links);
};

const sortLinks = (repossession_link_id, direction, config = undefined) => {
  return axios
    .post(
      `/api/v1/repossessions/links/sort`,
      {
        repossession_link_id,
        direction,
      },
      config
    )
    .then(({ data }) => data.data);
};

export { getLinks, sortLinks };
