<template>
  <v-card>
    <v-card-title class="text-center">
      Storage information

      <span class="float-right">
        <v-menu>
          <template #activator="{ props: aProps }">
            <v-btn icon="mdi-dots-vertical" v-bind="aProps" variant="text" />
          </template>

          <v-list>
            <v-list-item v-can="'storage.archive'">
              <v-btn
                v-if="storage.archive"
                variant="text"
                color="success"
                data-target="btn-archive-vehicle"
                data-ajax-type="unarchive"
                :data-storage-id="storage.id"
                text="Unarchive"
                block
              />
              <v-btn
                v-else
                color="warning"
                variant="text"
                data-target="btn-archive-vehicle"
                data-ajax-type="archive"
                :data-storage-id="storage.id"
                text="Archive"
                block
              />
            </v-list-item>

            <v-list-item>
              <v-btn
                variant="text"
                text="Refresh"
                block
                @click="refreshStorage"
              />
            </v-list-item>

            <v-list-item
              v-if="storage.vehicle_id"
              v-can="'vehicle.inspection.create'"
            >
              <v-btn
                variant="text"
                color="blue"
                data-target="btn-inspect-vehicle"
                data-ajax-type="new"
                :data-storage-id="storage.id"
                :data-vehicle-id="storage.vehicle_id"
                text="Inspection"
                block
              />
            </v-list-item>

            <v-list-item v-can="'storage.update'">
              <v-btn
                variant="text"
                color="blue"
                data-target="btn-storage"
                data-ajax-type="edit"
                :data-storage-id="storage.id"
                text="Edit"
                block
              />
            </v-list-item>

            <v-list-item v-can="'storage.delete'">
              <v-btn
                id="btn-delete-modal"
                variant="text"
                color="red"
                text="Delete"
                delelement="storage"
                :elementid="storage.id"
                block
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-card-title>

    <v-card-text>
      <v-table density="compact">
        <tbody>
          <tr>
            <th>Reference number</th>
            <td class="font-weight-bold">
              {{ storage.ref_num }}
            </td>
          </tr>

          <tr v-if="storage.dossier">
            <th scope="row">Docket number</th>
            <td>{{ storage.dossier }}</td>
          </tr>

          <tr v-if="storage.repossession">
            <th scope="row">Repossession</th>
            <td>
              <a
                class="font-weight-bold text-dark"
                :href="route('repossessions.show', [storage.repossession_id])"
              >
                {{ storage.repossession.ref_num }}
              </a>
            </td>
          </tr>

          <tr v-if="storage.contactPerson">
            <th scope="row">Contact Person</th>
            <td>
              <PeopleLink :person="storage.contactPerson" bold />
            </td>
          </tr>

          <tr>
            <th>Principal</th>
            <td>
              <PeopleLink v-if="storage.person" :person="storage.person" bold />
              <DepartmentLink
                v-else-if="storage.department"
                :department="storage.department"
                dep
                bold
              />
            </td>
          </tr>

          <tr v-if="storage.repossession">
            <th scope="row">Addressee(s)</th>
            <td>
              <LinkList
                :link-id="storage.repossession_id"
                res-type="debtor"
                link-type="repossession"
              />
            </td>
          </tr>

          <tr v-if="storage.impound">
            <th scope="row">Impound</th>
            <td>
              <a
                class="font-weight-bold text-dark"
                :href="route('impounds.show', [storage.impound.id])"
              >
                {{ storage.impound.ref_num }}
              </a>
            </td>
          </tr>

          <tr>
            <th scope="row">Date taken</th>
            <td>{{ formatDate(storage.in_date, 'fullDate') }}</td>
          </tr>

          <tr>
            <th scope="row">Released date</th>
            <td v-if="storage.out_date">
              {{ formatDate(storage.out_date, 'fullDate') }}
            </td>
            <td v-else>
              <div class="d-flex justify-content-between align-items-center">
                <span> Still on our property </span>
                <v-btn
                  v-can="'storage.release'"
                  v-tooltip="'Release'"
                  class="float-right"
                  variant="text"
                  color="red"
                  icon="mdi-location-exit"
                  data-target="btn-storage-release"
                  :data-storage-id="storage.id"
                />
              </div>
            </td>
          </tr>

          <tr v-if="storage.releaseBy">
            <th scope="row">Release authorized by</th>
            <td>
              <PeopleLink :person="storage.releaseBy" bold />
            </td>
          </tr>

          <tr v-if="storage.release_reason">
            <th scope="row">Release reason</th>
            <td>
              {{ storage.release_reason }}
            </td>
          </tr>

          <tr v-if="storage.price_day">
            <th scope="row">Price per day</th>
            <td>{{ formatMoney(storage.price_day) }}</td>
          </tr>

          <tr v-if="storage.inspection">
            <th scope="row">Inspection</th>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  {{
                    formatDate(
                      storage.inspection.inspection_date,
                      'fullDateTime'
                    )
                  }}
                </span>

                <v-btn
                  v-can="'vehicle.inspection.view'"
                  v-tooltip="'Open inspection'"
                  class="float-right"
                  variant="text"
                  color="blue"
                  icon="mdi-eye"
                  data-target="btn-inspect-vehicle"
                  data-ajax-type="show"
                  :data-inspect-id="storage.inspection.id"
                />
              </div>
            </td>
          </tr>

          <tr>
            <th>Register date (RIP)</th>
            <td>{{ formatDate(storage.created_at, 'fullDateTime') }}</td>
          </tr>

          <tr v-if="storage.archive">
            <th scope="row">Archive date</th>
            <td>{{ formatDate(storage.archive, 'fullDateTime') }}</td>
          </tr>
        </tbody>
      </v-table>

      <v-table
        v-if="storage.open_periods?.length"
        class="table-sm table-borderless table-striped table text-left"
        density="compact"
      >
        <thead class="thead-dark">
          <tr>
            <th class="text-center" scope="row" colspan="3">Open periods</th>
          </tr>
        </thead>

        <tr>
          <th class="text-left" scope="row">From</th>
          <th class="text-right" scope="row">Until</th>
          <th class="w-25 text-right" scope="row">Days</th>
        </tr>

        <tr v-for="(period, index) in storage.open_periods" :key="index">
          <td class="text-left">
            {{ formatDate(period.from, 'fullDate') }}
          </td>

          <td class="text-right">
            {{ formatDate(period.to, 'fullDate') }}
          </td>

          <td class="text-right">
            {{ period.days }}
          </td>
        </tr>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import LinkList from '@/components/general/LinksList.vue';
import { storage, fetchStorage } from '@/store/storage/storage.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { formatMoney } from '@/utils/formatter/number.js';

function refreshStorage() {
  if (storage.id) {
    fetchStorage(storage.id);
  }
}
</script>
