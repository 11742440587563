import axios from '../../plugins/axios.js';

const getLinks = (id, res_type, config = undefined) => {
  return axios
    .get(`/api/v1/reprimands/${id}/links/${res_type}`, config)
    .then(({ data }) => data.links);
};

const sortLinks = (reprimand_link_id, direction, config = undefined) => {
  return axios
    .post(
      `/api/v1/reprimands/links/sort`,
      { reprimand_link_id, direction },
      config
    )
    .then(({ data }) => data.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageAttachments = (
  storage_id,
  request,
  config = undefined
) => {
  return axios
    .get(route('api.v1.storages.files.index', [storage_id]), request, config)
    .then((response) => response.data.data);
};

export { getLinks, sortLinks };
